/* eslint-disable no-console */
import moment from "moment";
import LZUTF8 from "lzutf8";
import jwtDecode from "jwt-decode";
import { rest as RestService } from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../url/url";

const appId = process.env.REACT_APP_ORIGO_APP_ID;
const origoUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);

export const openPortalLogin = location => {
  // redirect to login page
  const path = `${origoUrl}auth?app_id=${appId}`;
  location.replace(path);
};

export const openPortal = location => {
  location.replace(origoUrl);
};

export const isTokenExpired = tokenData => {
  if (tokenData === null) {
    return true;
  }

  const { expiryTime } = tokenData;
  return moment().isAfter(expiryTime);
};

const addExpiryTimeToTokenData = data => {
  const expiresInSeconds = data.expiresIn;
  return {
    ...data,
    expiryTime: moment().add(expiresInSeconds, "second"),
  };
};

export const fetchTokenData = async sessionId => {
  const path = `${origoUrl}tokens`;
  const tokenData = await RestService.getToken(sessionId, path);
  return addExpiryTimeToTokenData(tokenData);
};

export const parseUserDataFromToken = userDataJson => {
  try {
    const organizations = JSON.parse(userDataJson);

    // gather
    const parsed = organizations.reduce(
      (acc, org) => {
        const result = acc;
        result.organizationIds.push(org.orgId);
        result.profitCenters.push(...org.profitCenters);
        const app = org.apps.find(x => x.id === appId);
        const roles = app ? app.roles : [];
        result.roles.push(...roles);
        return result;
      },
      {
        roles: [],
        organizationIds: [],
        profitCenters: [],
      }
    );

    // return without duplicates
    return {
      roles: Array.from(new Set(parsed.roles)),
      organizationIds: parsed.organizationIds,
      profitCenters: Array.from(new Set(parsed.profitCenters)),
    };
  } catch (error) {
    console.error("Error parsing user data from token", error);
    return {
      roles: [],
      organizations: [],
      profitCenters: [],
    };
  }
};

export const decompressUserData = data =>
  LZUTF8.decompress(data, { inputEncoding: "Base64" });

export const parseUserData = idToken => {
  const payload = jwtDecode(idToken);

  const name = `${payload.given_name} ${payload.family_name}`;
  const { email } = payload;
  const userId = payload["cognito:username"];
  const userDataDecompressed = decompressUserData(payload.user_data);
  const tokenData = parseUserDataFromToken(userDataDecompressed);

  // log for now
  console.log("User roles:", tokenData.roles);
  console.log("User organizations:", tokenData.organizationIds);
  console.log("User profitCenters:", tokenData.profitCenters);

  return {
    userId,
    name,
    email,
    roles: tokenData.roles,
    organizationIds: tokenData.organizationIds,
    profitCenters: tokenData.profitCenters,
  };
};
